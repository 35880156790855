import React from 'react'

const Blog = () => {
  return (
    <div>
      <h1 className="py-1">Blog: Coming Soon...</h1>

    </div>
  )
};
export default Blog;
