export const GET_CONTACTS = 'GET_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const FILTER_CONTACTS = 'FILTER_CONTACTS';
export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_VACCINES = 'GET_VACCINES';
export const ADD_VACCINE = 'ADD_VACCINE';
export const DELETE_VACCINE = 'DELETE_VACCINE';
export const UPDATE_VACCINE = 'UPDATE_VACCINE';
export const FILTER_VACCINES = 'FILTER_VACCINES';
export const CLEAR_VACCINES = 'CLEAR_VACCINES';
export const VACCINE_ERROR = 'VACCINE_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
// export const DATE_PICKER = 'DATE_PICKER';


